import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsUsersService {

  constructor() { }

  Show(seccion, item){

    let user = JSON.parse(localStorage.getItem('user'));
    // console.log(user)

    let rol = user.position;

    if(seccion === 'menu'){

      switch(item) {

        case 'suscripcion':
          return ['Agente', 'tecnico'].includes(rol) ? false : true;

        case 'dashboard':
          return [].includes(rol) ? false : true;

        case 'tablero':
          return [].includes(rol) ? false : true;

        case 'alertas':
          return ['Agente'].includes(rol) ? false : true;

        case 'poligonos':
          return [].includes(rol) ? false : true;
          
        case 'usuarios':
          return ['Agente'].includes(rol) ? false : true;
          
        case 'notificaciones':
          return ['Agente'].includes(rol) ? false : true;

      }

    }

    if(seccion === 'dashboard'){

      switch(item) {

        case 'siniestro_num':
          return ['Agente'].includes(rol) ? false : true;

        case 'causa':
          return [].includes(rol) ? false : true;

        case 'status':
          return ['Agente'].includes(rol) ? false : true;

        case 'ver_app':
          return ['Agente'].includes(rol) ? false : true;

        case 'despacho':
          return ['Agente'].includes(rol) ? false : true;

        case 'asignar':
          return ['Agente'].includes(rol) ? false : true;

        case 'tab_inspecciones':
          return ['Agente'].includes(rol) ? false : true;

        case 'filtro_despacho':
          return !['Super manager'].includes(rol) ? false : true;
        
				case 'filtro_coords':
        return !['Super manager', 'Agente'].includes(rol) ? false : true;

      }

    }

    if(seccion === 'mapa'){

      switch(item) {

        // case 'riesgos':
        //   return ['Agente'].includes(rol) ? false : true;

        case 'score_NDVI':
          return ['Agente'].includes(rol) ? false : true;


      }

    }

    if(seccion === 'detalles_proyecto'){

      switch(item) {

        case 'calificacion':
          return ['Agente'].includes(rol) ? false : true;

        case 'inspecciones':
          return ['Agente'].includes(rol) ? false : true;

        case 'validar':
          return ['Agente'].includes(rol) ? false : true;

        case 'historial':
          return [''].includes(rol) ? false : true;

        case 'historial-action':
          return ['Agente'].includes(rol) ? false : true;
      }

    }

    return true;
    
  }
}
